import QuoteRoutes from '../route-types'

export default {
  inject: ['$vv'],
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    validationItems() {
      switch (this.$route.name) {
        case QuoteRoutes.QuoteLabour.name:
          return this.$v.entity.labours
        case QuoteRoutes.QuoteOtherLabour.name:
          return this.$v.entity.others
        case QuoteRoutes.QuotePart.name:
          return this.$v.entity.parts
        case QuoteRoutes.QuoteOpg.name:
          return this.$v.entity.opgs
        case QuoteRoutes.QuoteMisc.name:
          return this.$v.entity.miscs
        case QuoteRoutes.QuoteSublet.name:
          return this.$v.entity.sublets
      }
    }
  },
  methods: {
    isValidItemNo(quoteItemId) {
      if (this.validationItems) {
        const index = this.validationItems.$model.findIndex(i => i.quoteItemId === quoteItemId)
        if (this.validationItems.$each[index]) {
          return this.validationItems.$each[index].itemNo.required
        } else {
          return false
        }
      }
    },
    isValidItemDesc(quoteItemId) {
      if (this.validationItems) {
        const index = this.validationItems.$model.findIndex(i => i.quoteItemId === quoteItemId)
        if (this.validationItems.$each[index]) {
          return this.validationItems.$each[index].itemDesc.required
        } else {
          return false
        }
      }
    }
  }
}
