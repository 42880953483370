import { ItemCategoryTypes } from '@/enums'
import QuoteTotalsMethodMixin from './QuoteTotalsMethodMixin'
import QuoteItemsMixin from './QuoteItemsMixin'

export default {
  mixins: [QuoteTotalsMethodMixin, QuoteItemsMixin],
  computed: {
    miscTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.miscs, ItemCategoryTypes.MISC)
      }
    }
  },
  methods: {
    updateValue(index, item) {
      // if (item && item.deleteOtherItem && item.value > 0) {
      //   item.value = item.value * -1
      // }
      this.updateTotal()
    }
  }
}
