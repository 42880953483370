import { mapGetters, mapActions } from 'vuex'

const moduleName = 'quotes'

export default {
  computed: {
    ...mapGetters(moduleName, {
      baseSnapshot: 'baseSnapshot',
      currentSnapshot: 'currentSnapshot',
      isUpdateInvoice: 'isUpdateInvoice',
      isUpdateExcessInvoice: 'isUpdateExcessInvoice',
      invoiceIncludedQuotes: 'invoiceIncludedQuotes',
      isFixedGst: 'isFixedGst',
      invoiceExtraCharge: 'invoiceExtraCharge'
    }),
    fixedGst: {
      get() {
        return this.isFixedGst
      },
      set(value) {
        this.setIsFixedGst(value)
      }
    },
    extraCharge: {
      get() {
        return this.invoiceExtraCharge
      },
      set(value) {
        this.setInvoiceExtraCharge(value)
      }
    }
  },
  methods: {
    ...mapActions(moduleName, [
      'setIsUpdateInvoice',
      'setIsUpdateExcessInvoice',
      'setIsFixedGst',
      'addInvoiceIncludedQuotes',
      'removeInvoiceIncludedQuotes',
      'clearInvoiceIncludedQuotes',
      'setInvoiceExtraCharge'
    ]),
    getIncludedStatus(quote) {
      const isIncluded = this.invoiceIncludedQuotes.findIndex(s => s.quoteId === quote.quoteId) >= 0
      return isIncluded
    }
  }
}
