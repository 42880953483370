import _get from 'lodash.get'

export default {
  methods: {
    isLineDifferentFromLastCommunication(item) {
      try {
        return this.isOrmLineDifferentFromLastCommunication(item)
      } catch(ex) {
        console.log(ex)
        return false
      }
    },
    getCommunicationFieldsToCheck(item) {
      let target = this.getTargetEntity()

      if (target.orm !== null) {
        return this.getOrmFieldsToCheck(item)
      }

      return []
    },
    lookupItemElementName(value) {
      let lookup = {
        0: 'MECH',
        1: 'Misc',
        2: 'PAINT',
        3: 'PaintMaterials',
        4: 'Part',
        5: 'RR',
        6: 'REP',
        7: 'Towing',
        'Sublet': 'SUBL'
      }

      return lookup[value]
    },
    getOrmFieldsToCheck(item) {
      let fieldsToCheck = {
        //lineNumber: 'itemNo',
        //itemNo: 'code',
        itemDesc: 'description',
        itemType: (item) => {
          let name = item.itemElementName
          if(name == '1') {
            if(item?.item?.sublet !== null) {
              name = 'Sublet'
            }
          }

          return this.lookupItemElementName(name).toUpperCase()
        }
      }

      if(item.itemType == 'PART') {
        fieldsToCheck['itemQuantity'] = 'item.partQuantity'
        fieldsToCheck['value'] = 'item.partValue'
        fieldsToCheck['partNo'] = 'item.partSource.item.partNumber'
      } else if(item.itemType == 'MISC') {
        fieldsToCheck['value'] = 'quoteItemValue'
      } else if(item.itemType == 'SUBL') {
        fieldsToCheck['value'] = 'quoteItemValue'
      } else {
        fieldsToCheck['hourValue'] = 'item.hours'
        fieldsToCheck['dollarValue'] = 'quoteItemValue'
      }

      return fieldsToCheck
    },
    isOrmFieldDifferenceFromLastCommunication(item, fieldName) {

      let target = this.getTargetEntity()

      if (target.orm === null) {
        return false
      }

      let fieldsToCheck = this.getOrmFieldsToCheck(item)

      let exFieldName = fieldsToCheck[fieldName]
      const historyLine = this.getLastOrmHistoryLine(item)
      let exItem = historyLine ? historyLine.record : null

      let value1 = _get(item, fieldName)

      let value2 = null
      if(typeof exFieldName === 'function') {
        value2 = exFieldName(exItem)
      } else {
        value2 = _get(exItem, exFieldName)
      }

      // IGNORE CASE
      let isDifferent = (value1+'').toLocaleUpperCase().trim() != (value2+'').toLocaleUpperCase().trim()

      if(isDifferent) {
        console.log(item.itemDesc, item.itemType,fieldName,value1,value2)
      }

      return isDifferent
    },
    isOrmLineDifferentFromLastCommunication(item) {

      let target = this.getTargetEntity()

      if (target.orm === null) {
        return false
      }

      let fieldsToCheck = this.getCommunicationFieldsToCheck(item)
      for(let fieldName of Object.keys(fieldsToCheck)) {
        if(this.isOrmFieldDifferenceFromLastCommunication(item, fieldName)) {
          return true
        }
      }

      return false
    },
    getLastOrmHistoryLine(item) {

      let target = this.getTargetEntity()

      if(target.ormHistory === undefined || target.ormHistory[item.lineNumber] === undefined) {
        return null
      }

      return target.ormHistory[item.lineNumber].slice(-1)[0]
    },
    getTargetEntity() {
      var target = this.innerValue
      if(this.innerValue === undefined) {
        target = this.value
      }
      return target
    }
  }
}