import { ItemCategoryTypes } from '@/enums'
import QuoteTotalsMethodMixin from './QuoteTotalsMethodMixin'
import QuoteItemsMixin from './QuoteItemsMixin'

export default {
  mixins: [QuoteTotalsMethodMixin, QuoteItemsMixin],
  computed: {
    subletTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.sublets, ItemCategoryTypes.SUBL)
      }
    }
  },
  methods: {
    updateValue(index, item) {
      if (item && item.deleteOtherItem && item.value > 0) {
        item.value = item.value * (item.itemQuantity !== 0 ? item.itemQuantity : 1)
      }
      this.updateTotal()
    }
  }
}
