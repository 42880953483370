import { QuotingMethodTypes, ItemCategoryTypes } from '@/enums'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  computed: {
    mixinEntity() {
      return this.entity || this.innerValue
    }
  },
  methods: {
    calculateItemCategoryTotal(quotingMethod, items, itemType) {
      let total = 0
      if (itemType === ItemCategoryTypes.PART) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.itemQuantity * item.markupValue)
          }, 0)
      } else if (itemType === ItemCategoryTypes.MISC) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else if (itemType === ItemCategoryTypes.OPG) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate)
          }, 0)
      } else if (itemType === ItemCategoryTypes.SUBL) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && i.itemType === itemType)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.dollarValue)
          }, 0)
      }
      return total
    },
    calculateItemsTotal(quotingMethod, items) {
      let total = 0
      total += items
        .filter((i) => !i.deleted && !i.reportOnly && i.itemType === ItemCategoryTypes.PART)
        .reduce(function (subTotal, item) {
          return roundAwayFromZero(subTotal + item.itemQuantity * item.markupValue)
        }, 0)
      total += items
        .filter((i) => !i.deleted && !i.reportOnly && (i.itemType === ItemCategoryTypes.MISC || i.itemType === ItemCategoryTypes.SUBL))
        .reduce(function (subTotal, item) {
          return subTotal + item.value
        }, 0)
      total += items
        .filter((i) => !i.deleted && !i.reportOnly && i.itemType === ItemCategoryTypes.OPG)
        .reduce(function (subTotal, item) {
          return subTotal + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate)
        }, 0)

      total += items
        .filter(
          (i) =>
            !i.deleted &&
            !i.reportOnly &&
            i.itemType !== ItemCategoryTypes.OPG &&
            i.itemType !== ItemCategoryTypes.PART &&
            i.itemType !== ItemCategoryTypes.MISC &&
            i.itemType !== ItemCategoryTypes.SUBL
        )
        .reduce(function (subTotal, item) {
          return roundAwayFromZero(subTotal + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : roundAwayFromZero(item.hourValue * item.rate)))
        }, 0)
      return total
    }
  }
}
