import { ItemCategoryTypes, QuotingMethodTypes } from '@/enums'
import QuoteTotalsMethodMixin from './QuoteTotalsMethodMixin'
import QuoteItemsMixin from './QuoteItemsMixin'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  mixins: [QuoteTotalsMethodMixin, QuoteItemsMixin],
  computed: {
    opgTotal() {
      if (this.mixinEntity) {
        return this.calculateItemCategoryTotal(this.mixinEntity.quotingMethod, this.mixinEntity.opgs, ItemCategoryTypes.OPG)
      }
    }
  },
  methods: {
    updateValue(index, item) {
      // if (item && item.deleteOtherItem && item.hourValue > 0) {
      //   item.hourValue = item.hourValue * -1
      // }
      if (this.value.quotingMethod === QuotingMethodTypes.Dollar) {
        item.dollarValue = item.hourValue
      } else {
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
      }
      this.updateTotal()
    },
    getOpgRate(item, index) {
      if (this.value.quotingMethod === QuotingMethodTypes.Hour) {
        let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((r) => r.labourCodeId === item.opgCode)
        if (!rate) {
          const labourType = this.$labourTypes.find((t) => t.labourTypeCode === PaintGroupTypes.PAINT)
          const newRate = {
            quoteId: this.innerValue.quoteId,
            labourCodeId: item.opgCode,
            labourTypeId: labourType.labourTypeId,
            rate: this.isNtar ? this.innerValue.shopRate : 0,
            modifiedBy: '',
            modifiedDate: null,
            createdBy: '',
            createdDate: null,
            isNew: true,
            quoteVersion: 0,
            deleted: false
          }
          this.innerValue.rates.push(newRate)
          rate = newRate
        }
        // this.items[index].rate = rate.rate
        // this.items[index].dollarValue = this.items[index].hourValue * this.items[index].rate
        item.rate = rate.rate
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
      }
      this.$emit('opg-code-changed')
      this.updateTotal()
    },
    getDefaultRate(item, index) {
      let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((r) => r.labourCodeId === item.opgCode)
      return rate.rate
    }
  }
}
