import { mapGetters } from 'vuex'
import { AudanetLockNotification } from '../components'
import QuoteAssessmentMixin from './QuoteAssessmentMixin'

export default {
  components: { AudanetLockNotification },
  mixins: [QuoteAssessmentMixin],
  computed: {
    defaultRowColourClass: function () {
      return (item, itemAssessments, displayDeleted) => {
        let classText = []
        if (this.isItemDeleted(item, itemAssessments) && displayDeleted !== undefined ) {
          classText.push('is-delete-real-item')
          let isOutstandingOrdered = item.quantityOrdered > 0 && item.quantityReturned < item.quantityOrdered
          if (isOutstandingOrdered) {
            classText.push('is-delete-item')
          } else if (!displayDeleted) {
            classText.push('is-hidden')
          }
        }
        else if (this.isItemRemoved(item, itemAssessments)) {
          classText.push('strike-through-line')
        }

        if (item.deleteOtherItem && (item.value < 0 || item.dollarValue < 0)) {
          classText.push('is-delete-item')
        } else if (item.isNew && !item.deleteOtherItem) {
          classText.push('is-new-item')
        } else if (this.baseItemDiff(item)) {
          classText.push('is-changed-item')
        }

        /*if(this.isLineDifferentFromLastCommunication(item)) {
          classText.push('has-background-warning')
        }*/

        return classText
      }
    },
    ...mapGetters('quotes', {
      baseSnapshot: 'baseSnapshot',
      baseItem: 'baseItem',
      baseItemDiff: 'baseItemDiff'
    })
  },
  methods: {
    getBaseItem(itemId, itemType) {
      if (!this.baseSnapshot) {
        return false
      }
      let baseItem = null
      switch (itemType) {
        case ItemCategoryTypes.RR:
        case ItemCategoryTypes.REP:
        case ItemCategoryTypes.RWA:
        case ItemCategoryTypes.PAINT:
          baseItem = this.baseSnapshot.labours.find((i) => i.quoteItemId === itemId)
          break
        case ItemCategoryTypes.MECH:
        case ItemCategoryTypes.CD:
        case ItemCategoryTypes.CRUSH:
        case ItemCategoryTypes.FIBER:
          baseItem = this.baseSnapshot.others.find((i) => i.quoteItemId === itemId)
          break
        case ItemCategoryTypes.PART:
          baseItem = this.baseSnapshot.parts.find((i) => i.quoteItemId === itemId)
          break
        case ItemCategoryTypes.OPG:
          baseItem = this.baseSnapshot.opgs.find((i) => i.quoteItemId === itemId)
          break
        case ItemCategoryTypes.MISC:
          baseItem = this.baseSnapshot.miscs.find((i) => i.quoteItemId === itemId)
          break
        case ItemCategoryTypes.SUBL:
          baseItem = this.baseSnapshot.sublets.find((i) => i.quoteItemId === itemId)
          break
        default:
          baseItem = null
      }
      return baseItem
    }
  },
  watch: {
    items: {
      // This is to update item's itemStatus when changed
      // Reset to original value if nothing changed
      handler: function (newVal, oldVal) {
        // console.log('items changed')
        if (oldVal) {
          const vm = this
          newVal.forEach(function (i) {
            // const diff = vm.baseItemDiff(i)
            // const itemStatusDiff = diff ? diff.find(d => d.kind === 'E' && d.path[0] === 'itemStatus') : null
            // // console.log('Changed', itemStatusDiff)
            // if (diff && diff.length === 1 && itemStatusDiff) {
            //   const baseItem = vm.baseItem(i)
            //   i.itemStatus = baseItem.itemStatus
            // } else if (diff) {
            //   // console.log(vm.baseItemDiff(i))
            //   i.itemStatus = 'C'
            // }
            const diff = vm.baseItemDiff(i, ['itemStatus'])
            const itemStatusDiff = vm.baseItemDiff(i, ['itemStatus'], false)
            // console.log('Changed', itemStatusDiff)
            if (diff && itemStatusDiff) {
              const baseItem = vm.baseItem(i)
              i.itemStatus = baseItem.itemStatus
            } else if (diff) {
              // console.log(vm.baseItemDiff(i))
              i.itemStatus = 'C'
            }
          })
        }
      },
      deep: true
    }
  }
}
